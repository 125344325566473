import React from "react"

import Layout from "../components/layout";
import SEO from "../components/seo";

import pagesStyles from "../styles/pages.module.styl";

import fish from "../images/404.png";

const NotFoundPage = () => (
  <Layout>
    <br />
    <SEO title="404: Not found" />
    <div className={pagesStyles.notFound}>
      <div className={pagesStyles.notFoundFishes}>
        <img src={fish} alt="¡Oops! Esta página no existe."
             className={pagesStyles.deadFish}/>
      </div>
      <h1>¡Oops!</h1>
      <p>Esta página no existe. </p>
    </div>
  </Layout>
)

export default NotFoundPage
